import React, { useState, useEffect } from "react";
import { useRouter } from "next/router";
import { Button, Dropdown } from "artisn-ui-react";

import Styles, { DropdownOverlayStyled } from "./SelectMenuDropdown.styles";
import { SelectCityDropdownProps as Props } from "./SelectMenuDropdown.types";
import CONSTANTS from "config/constants";
import useWindowSize from "hooks/useWindowSize";
import useAuth from "contexts/auth/auth.context.hooks";
import useSignOut from "hooks/useSignOut";
import { createSuccessNotification } from "utils/notifications.utils";
import { useFetchUser } from "services/user/user.service.hooks";
import useVendors from "contexts/vendors/vendors.hooks";

import MenuSVG from "../../../../public/assets/images/SelectMenuDropdown/menu.svg";
import HeartSVG from "../../../../public/assets/images/SelectMenuDropdown/menu-heart.svg";
import HelpSVG from "../../../../public/assets/images/SelectMenuDropdown/menu-help.svg";
import QuestionsSVG from "../../../../public/assets/images/SelectMenuDropdown/menu-questions.svg";
import PendingSVG from "../../../../public/assets/images/SelectMenuDropdown/menu-pending.svg";
import RegisterSVG from "../../../../public/assets/images/SelectMenuDropdown/menu-register.svg";
import LoginSVG from "../../../../public/assets/images/SelectMenuDropdown/menu-login.svg";
import HistorySVG from "../../../../public/assets/images/SelectMenuDropdown/menu-history.svg";
import SignOutSVG from "../../../../public/assets/images/SelectMenuDropdown/menu-signout.svg";
import HouseSVG from "../../../../public/assets/images/SelectMenuDropdown/menu-house.svg";

const { BREAKPOINTS } = CONSTANTS;
const { tablet } = BREAKPOINTS;

const SelectMenuDropdown: React.FC<Props> = props => {
  const { className } = props;
  const { width, availWidth } = useWindowSize();
  const isMobile = width <= tablet;
  const { isAnonymous } = useAuth();
  const signOut = useSignOut();
  const { push } = useRouter();
  const [isScrollDisabled, setIsScrollDisabled] = useState(false);
  const { data: user } = useFetchUser();
  const { vendorId, origin } = user ?? {};
  const { setUserVendorId } = useVendors();

  const signOutHandler = () => {
    signOut();
    createSuccessNotification("Has cerrado sesión exitosamente");
    push("/");
  };

  const overlayNode = (
    <DropdownOverlayStyled
      className="DropdownOverlay DropdownOverlayMenu"
      width={availWidth}
    >
      <div className="DropdownOverlay__menu">
        {isAnonymous ? (
          <div
            className="DropdownOverlay__menu__item"
            onClick={() => push("/signup")}
          >
            <RegisterSVG /> Registrarme
          </div>
        ) : (
          <div
            className="DropdownOverlay__menu__item"
            onClick={() => push("/profile/user")}
          >
            <LoginSVG /> Mi cuenta
          </div>
        )}
        <div
          className="DropdownOverlay__menu__item"
          onClick={() => push("/checkout")}
        >
          <PendingSVG /> Procesos pendientes
        </div>
        {!isAnonymous ? (
          <div
            className="DropdownOverlay__menu__item"
            onClick={() => push("/profile/orders")}
          >
            <HistorySVG /> Historial de adopciones
          </div>
        ) : null}
        <div
          className="DropdownOverlay__menu__item"
          onClick={() => push("/frequent-questions")}
        >
          <QuestionsSVG /> Preguntas frecuentes
        </div>
        <div
          className="DropdownOverlay__menu__item"
          onClick={() => push("/profile/wishlist")}
        >
          <HeartSVG /> Favoritos
        </div>
        {!isAnonymous && vendorId && origin?.toUpperCase() === "ADMIN" ? (
          <div
            className="DropdownOverlay__menu__item"
            onClick={() => push("/profile/admin")}
          >
            <HouseSVG /> Administrador
          </div>
        ) : null}
        {!isAnonymous ? (
          <div className="DropdownOverlay__menu__item" onClick={signOutHandler}>
            <SignOutSVG /> Cerrar sesión
          </div>
        ) : null}
        <div className="DropdownOverlay__help">
          <HelpSVG />
          <div className="DropdownOverlay__help__content">
            <p className="DropdownOverlay__help__title">
              ¿Necesitas ayuda para elegir?
            </p>
            <p
              className="DropdownOverlay__help__description"
              onClick={() => push("/wizard")}
            >
              Te ayudamos a encontrar tu amigo ideal
            </p>
          </div>
        </div>
      </div>
    </DropdownOverlayStyled>
  );

  const buttonNode = (
    <Button className="SelectCityDropdown__button" onClick={() => {}}>
      <MenuSVG />
    </Button>
  );

  useEffect(() => {
    if (!isMobile) return;
    if (isScrollDisabled) {
      setTimeout(() => {
        document.body.style.overflow = "hidden";
      }, 100);
      return;
    }
    document.body.style.overflow = "unset";
  }, [isMobile, isScrollDisabled]);

  useEffect(() => {
    if (!vendorId || origin?.toUpperCase() !== "ADMIN") return;
    setUserVendorId(vendorId);
  }, [origin, setUserVendorId, vendorId]);

  return (
    <Styles
      className={`SelectCityDropdown ${className}`}
      onClick={() => {
        setIsScrollDisabled(prev => !prev);
      }}
    >
      <Dropdown
        className="SelectCityDropdown__dropdown"
        overlay={overlayNode}
        target={["click"]}
        position={isMobile ? "right" : "left"}
        isFixed
      >
        {buttonNode}
      </Dropdown>
    </Styles>
  );
};

SelectMenuDropdown.defaultProps = {};

export default SelectMenuDropdown;
