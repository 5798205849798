import styled from "styled-components";

import { SearchBarProductsStyledProps as Props } from "./SearchBarProducts.types";
import { SearchBarContentStyledProps as ContentProps } from "./SearchBarProducts.types";
import CONSTANTS from "config/constants";

const { mobile, tablet } = CONSTANTS.BREAKPOINTS;

const SearchBarProductsStyled = styled.div<Props>`
  display: flex;
  position: relative;

  @media (max-width: ${tablet}px) {
    transform: ${({ showSearchBar }) =>
      showSearchBar
        ? "translateY(calc(2.4rem + var(--sizes-navbar-height-mobile-without-search)))"
        : "translateY(-19.2rem)"};
  }

  .SearchBarProducts {
    &__search-bar {
      width: 100%;
      max-width: 29.6rem;

      @media (max-width: ${tablet}px) {
        max-width: 100%;
      }
    }

    &__btn-cancel {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      z-index: 3;
      font-size: 1.2rem;
      font-weight: 600;
      background-color: var(--palette-gray-s0-l98);
      color: var(--palette-primary-dark);
    }
  }

  &&& .SearchBar__input {
    padding: 1rem 1.2rem;
    border: none;
    line-height: 2.4rem;
    background-color: var(--palette-white);
    font-weight: 500;
    padding-right: 3.2rem;
    color: var(--palette-blue-s51-l16);

    @media (max-width: ${tablet}px) {
      font-size: 1.6rem;
    }

    &:focus {
      box-shadow: none;
    }
  }

  .SearchBar__wrapper {
    flex-direction: row-reverse;
    justify-content: space-evenly;
    border-radius: 5rem;
    align-items: center;
    padding: 0 1rem;
    background-color: var(--palette-white);
    box-shadow: 0rem 0.4rem 1.6rem 0rem #3333330f;
  }

  &&& .SearchBar__btn-search {
    padding-left: 0;
    border-radius: 100%;
    width: 4.4rem;
    height: 4.4rem;
    background-color: var(--palette-primary);
  }

  .SearchBar__btn-container {
    position: absolute;
    right: 4.6rem;
    z-index: 2;
  }
`;

const SearchBarContentStyled = styled.div<ContentProps>`
  position: fixed;
  width: 32rem;
  max-height: calc(100vh - 15.6rem);
  overflow: auto;
  padding-top: 2rem;
  z-index: 2;
  overscroll-behavior: contain;

  @media (max-width: ${mobile}px) {
    width: var(--sizes-page-maxWidth);
  }

  .SearchBarContent {
    &__container {
      background-color: var(--palette-white);
    }

    &__clean-button {
      padding: 0.2rem 1.2rem;
      border-radius: 4.8rem;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0.2rem 1.2rem;
      font-size: 1.2rem;
      color: var(--palette-gray-s0-l40);
      text-align: center;
      border-radius: 4.8rem;
      background-color: var(--palette-gray-s0-l95);
      cursor: pointer;
    }

    &__recent-searches-container {
      padding: 1.2rem 2rem;
      background-color: var(--palette-white);
      margin-bottom: 0.8rem;
      max-width: 100%;

      @media (max-width: ${mobile}px) {
        padding: 1.2rem;
      }
    }

    &__section-info {
      display: flex;
      justify-content: space-between;
      padding: 0 0.8rem;
      padding-bottom: 1.6rem;
    }

    &__recent-searches-item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0.8rem;
      cursor: pointer;
    }

    &__recent-searches-query {
      max-width: 90%;
      font-size: 1.6rem;
      color: var(--palette-blue-s51-l16);
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    &__no-recent-searches {
      font-size: 1.4rem;
      margin-left: 1rem;
      font-weight: 600;
    }

    &__content-container {
      padding: 1.2rem 0;
      background-color: var(--palette-white);
    }

    &__section-title {
      font-size: 1.4rem;
      color: var(--palette-gray-s210-l4-a52);
    }

    &__section-padding {
      padding: 0 2.4rem;
    }

    &__dropdown-item {
      padding: 0.4rem 1.6rem;
      display: flex;
      align-items: center;
      cursor: pointer;

      &:not(:last-child) {
        border-bottom: 0.4rem solid var(--palette-gray-s0-l98);
      }

      .Image {
        min-width: 10rem;
      }
    }

    &__item-info {
      padding: 0.8rem;
    }

    &__item-name {
      font-size: 1.2rem;
      color: var(--palette-black-s0-l10);
      max-width: 100%;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }

    &__item-number {
      font-size: 1.2rem;
      color: var(--palette-gray-s0-l80);
    }
  }
`;

export { SearchBarContentStyled };
export default SearchBarProductsStyled;
