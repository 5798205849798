import styled from "styled-components";

import { SelectCityDropdownStyledProps as Props } from "./SelectMenuDropdown.types";
import { DropdownOverlayStyledProps as OverlayProps } from "./SelectMenuDropdown.types";
import CONSTANTS from "config/constants";

const { tablet } = CONSTANTS.BREAKPOINTS;

const SelectCityDropdownStyled = styled.div<Props>`
  &.SelectCityDropdown {
  }

  .SelectCityDropdown {
    &__button {
      border-radius: 5.6rem;
      background-color: var(--palette-white);
      border-color: var(--palette-white);
      padding: 1.6rem 1.6rem;
      box-shadow: 0rem 0.4rem 1.6rem 0rem #3333330f;
    }
  }
`;

export const DropdownOverlayStyled = styled.div<OverlayProps>`
  @media (max-width: ${tablet}px) {
    width: ${props => props.width + "px"};
  }

  .DropdownOverlay {
    &__menu {
      @media (max-width: ${tablet}px) {
        border-top-left-radius: 2.4rem;
        border-top-right-radius: 2.4rem;
        background-color: var(--palette-white);
      }

      &__item {
        color: var(--palette-blue-s51-l16);
        font-size: 1.6rem;
        border-bottom: 0.1rem solid var(--palette-gray-s0-l95);
        padding: 1.6rem 0;
        font-weight: 600;
        margin: 0 2rem;
        cursor: pointer;
        align-items: center;
        display: flex;

        svg {
          margin-right: 1.2rem;
        }
      }
    }

    &__help {
      background-color: var(--palette-blue-s51-l16);
      display: flex;
      padding: 2.8rem 2rem;

      &__title {
        color: var(--palette-white);
        font-size: 1.4rem;
      }

      &__description {
        color: var(--palette-purple-s66-l84);
        font-size: 1.6rem;
        font-weight: 600;
        width: 21.6rem;
        padding-top: 0.4rem;
        line-height: 2rem;

        &:hover {
          cursor: pointer;
        }
      }

      svg {
        margin-right: 1.2rem;
      }
    }
  }
`;

export default SelectCityDropdownStyled;
