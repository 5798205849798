import React from "react";
import Link from "next/link";

import Styles from "./Logo.styles";
import { LogoProps as Props } from "./Logo.types";
import CONSTANTS from "config/constants";
import useTalkShop from "contexts/talkShop/talkShop/talkShop.context.hooks";

import LogoSVG from "../../../../public/assets/images/Logos/mosumi.svg";
import LogoMobileSVG from "../../../../public/assets/images/Logos/mosumi-mobile.svg";

const { TITLE } = CONSTANTS;
const { WITH_TALK_SHOP } = CONSTANTS.FEATURE_FLAGS;

const Logo: React.FC<Props> = props => {
  const { className, onClick } = props;
  const label = `${TITLE} logo`;
  const { talkShopProvider, talkShopIdentifier } = useTalkShop();
  const talkShop = WITH_TALK_SHOP && !!talkShopProvider && !!talkShopIdentifier;
  const href = talkShop ? "" : "/";

  return (
    <Styles className={`Logo ${className}`}>
      {onClick ? (
        <div onClick={onClick} aria-label={label}>
          <LogoMobileSVG className="Logo__mobile" />
          <LogoSVG className="Logo__desktop" />
        </div>
      ) : (
        <Link href={href} passHref aria-label={label}>
          <a aria-label={label}>
            <LogoMobileSVG className="Logo__mobile" />
            <LogoSVG className="Logo__desktop" />
          </a>
        </Link>
      )}
    </Styles>
  );
};

Logo.defaultProps = {
  className: ""
};

export default Logo;
