import React, { useState, useEffect } from "react";
import { Button } from "artisn-ui-react";
import { useRouter } from "next/router";
import { getShoppingCartProducts } from "artisn/shopping-cart";

import Logo from "../Logo/Logo";
import Styles from "./Navbar.styles";
import CONSTANTS from "config/constants";
import { NavbarProps as Props } from "./Navbar.types";
import SearchBarProducts from "../SearchBarProducts/SearchBarProducts";
import useTalkShop from "contexts/talkShop/talkShop/talkShop.context.hooks";
import SelectMenuDropdown from "../SelectMenuDropdown/SelectMenuDropdown";
import SelectCityDropdown from "../SelectCityDropdown/SelectCityDropdown";
import useShoppingCart from "contexts/shoppingCart/shoppingCart.context.hooks";
import { useFetchUser } from "services/user/user.service.hooks";

import PendingSVG from "../../../../public/assets/images/SelectMenuDropdown/menu-pending.svg";

const { FEATURE_FLAGS } = CONSTANTS;
const { WITH_TALK_SHOP } = FEATURE_FLAGS;

const Navbar: React.FC<Props> = props => {
  const { className } = props;
  const { talkShopProvider, talkShopIdentifier } = useTalkShop();
  const talkShop = WITH_TALK_SHOP && talkShopProvider && talkShopIdentifier;
  const { shoppingCart } = useShoppingCart();
  const [hasPendingProcess, setHasPendingProcess] = useState(false);
  const { push } = useRouter();
  const { data: user } = useFetchUser();
  const { name } = user ?? {};

  useEffect(() => {
    if (shoppingCart && getShoppingCartProducts(shoppingCart).length) {
      setHasPendingProcess(true);
      return;
    }
    setHasPendingProcess(false);
  }, [shoppingCart]);

  return (
    <Styles className={`Navbar ${className}`} talkShop={!!talkShop} id="navbar">
      <main className="Navbar__content">
        <div className="Navbar__top Navbar__top--brand">
          <SelectMenuDropdown className="Navbar__menu" />
          {name && <span className="Navbar__username">{`Hola, ${name}!`}</span>}
          {hasPendingProcess && (
            <Button
              className="Navbar__process__button"
              onClick={() => push("/checkout")}
            >
              <PendingSVG />
              <span className="Navbar__process__title">Proceso pendiente</span>
            </Button>
          )}
          <SelectCityDropdown className="Navbar__city" />
          <Logo className="Navbar__logo" />
          <SearchBarProducts className="Navbar__search-bar" />
        </div>
      </main>
    </Styles>
  );
};

Navbar.defaultProps = {
  className: ""
};

export default Navbar;
