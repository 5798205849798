import React, { useEffect, useState } from "react";
import { SearchBar, useSearchBar, Image } from "artisn-ui-react";
import { useRouter } from "next/router";
import { events } from "@artisan-commerce/analytics-capacitor";

import Styles from "./SearchBarProducts.styles";
import { dropdownConfigs } from "utils/common.utils";
import { imageConfig } from "./SearchBarProducts.helpers";
import { SearchBarProductsProps as Props } from "./SearchBarProducts.types";
import { SearchBarContentStyled as ContentStyles } from "./SearchBarProducts.styles";
import { useFetchRecommendedProducts } from "services/products/products.service.hooks";
import { goToProductDetail } from "utils/seo.utils";
import { Pet, PetDetails } from "components/product/Product/Product.types";

import ClockSVG from "../../../../public/assets/images/clock.svg";
import SearchVG from "../../../../public/assets/images/search.svg";

const { Item } = SearchBar;
const { logSearchProductAttempt } = events.product;

const SearchBarProducts: React.FC<Props> = props => {
  const { className } = props;
  const router = useRouter();
  const [showSearchBarContent, setShowSearchBarContent] = useState(false);
  const [showSearchBar, setShowSearchBar] = useState(false);

  const instanceSearchBar = useSearchBar({
    name: "principal-searchBar"
  });

  const { cleanSearchHistory, recentSearches } = instanceSearchBar;

  const onClickItem = (product: Pet) => {
    const { storeId, vendorId } = (product as PetDetails) ?? {};
    goToProductDetail(product, storeId, vendorId);
  };

  const { data } = useFetchRecommendedProducts();

  const onClick = () => {
    setShowSearchBarContent(true);
  };

  const onClickCancel = () => {
    setShowSearchBarContent(false);
    setShowSearchBar(false);
  };

  const onSubmit = (query: string) => {
    logSearchProductAttempt({ query });

    router.push({
      pathname: "/pets",
      query: { q: query }
    });
  };

  const backdropConfig = {
    onClick: onClickCancel
  };

  useEffect(() => {
    let lastScroll = window.scrollY;
    const handleScroll = () => {
      if (lastScroll < window.scrollY) {
        setShowSearchBar(true);
      } else {
        setShowSearchBar(false);
      }
      lastScroll = window.scrollY;
    };
    window.addEventListener("scroll", handleScroll, true);

    return () => window.removeEventListener("scroll", handleScroll, true);
  }, []);

  return (
    <Styles
      className={`SearchBarProducts ${className}`}
      showSearchBar={showSearchBar || showSearchBarContent}
    >
      <SearchBar
        className="SearchBarProducts__search-bar"
        placeholder="Buscar"
        onSubmit={onSubmit}
        instance={instanceSearchBar}
        onInputClick={onClick}
        onInputBlur={onClickCancel}
        onClear={() => setShowSearchBarContent(false)}
        dropdownConfigs={{
          ...dropdownConfigs,
          showDropdown: showSearchBarContent,
          position: "right"
        }}
        backdropConfig={backdropConfig}
        SearchIcon={<SearchVG />}
      >
        <ContentStyles className="SearchBarContent">
          <div className="SearchBarContent__recent-searches-container">
            <div className="SearchBarContent__section-info">
              <h3 className="SearchBarContent__section-title">
                Búsquedas recientes
              </h3>
              <span
                onClick={cleanSearchHistory}
                className="SearchBarContent__clean-button"
              >
                Limpiar historial
              </span>
            </div>
            {recentSearches.length ? (
              <div className="SearchBarContent__recent-searches-item-container">
                {recentSearches.map((item, index) => (
                  <Item
                    key={index}
                    className="SearchBarContent__recent-searches-item"
                    query={item}
                  >
                    <p className="SearchBarContent__recent-searches-query">
                      {item}
                    </p>
                    <ClockSVG />
                  </Item>
                ))}
              </div>
            ) : (
              <p className="SearchBarContent__no-recent-searches">
                No hay búsquedas recientes
              </p>
            )}
          </div>
          <div className="SearchBarContent__content-container">
            <div className="SearchBarContent__section-info">
              <h3 className="SearchBarContent__section-title SearchBarContent__section-padding">
                Búsquedas populares
              </h3>
            </div>
            {data?.map((item, index) => {
              const { name, images } = item;

              return (
                <Item
                  key={index}
                  className="SearchBarContent__dropdown-item"
                  onClick={() => onClickItem(item)}
                  query={name}
                >
                  <Image
                    alt={name}
                    image={images[0]}
                    config={imageConfig}
                    placeholder="blur"
                  />
                  <div className="SearchBarContent__item-info">
                    <p className="SearchBarContent__item-name">{name}</p>
                    <p className="SearchBarContent__item-number">
                      {data.length} resultados
                    </p>
                  </div>
                </Item>
              );
            })}
          </div>
        </ContentStyles>
      </SearchBar>
    </Styles>
  );
};

SearchBarProducts.defaultProps = {
  className: ""
};

export default SearchBarProducts;
