import styled from "styled-components";

import { NavbarStyledProps as Props } from "./Navbar.types";
import CONSTANTS from "../../../config/constants";

const { BREAKPOINTS, FEATURE_FLAGS } = CONSTANTS;
const { tablet } = BREAKPOINTS;
const { WITH_SELECT_ADDRESS_DROPDOWN } = FEATURE_FLAGS;

const NavbarStyled = styled.div<Props>`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 3;
  width: 100%;
  background-color: var(--palette-cream-s42-l93);
  height: var(--sizes-navbar-height);
  display: grid;
  grid-template-columns: var(--sizes-page-columns);
  align-items: center;
  grid-template-areas: ". content .";

  @media (max-width: ${tablet}px) {
    height: var(--sizes-navbar-height-mobile);
    padding-top: env(safe-area-inset-top);
    padding-bottom: 0.8rem;
  }

  .Navbar {
    &__content {
      grid-area: content;
      max-height: 10rem;
    }

    &__icon {
      display: flex;
      align-items: center;
    }

    &__dropdown {
      margin-left: 2.4rem;
      flex-shrink: 0;

      @media (max-width: ${tablet}px) {
        margin-left: 1.6rem;
        flex-shrink: 1;
      }
    }

    &__menu {
      grid-area: menu;
    }

    &__username {
      grid-area: username;
      margin-left: 1.6rem;
      font-size: 1.6rem;
      font-weight: 600;

      @media (max-width: ${tablet}px) {
        display: none;
      }
    }

    &__process {
      &__button {
        grid-area: process;
        border-radius: 2rem;
        background-color: var(--palette-white);
        border-color: var(--palette-white);
        padding: 1.2rem 1.6rem;
        box-shadow: 0rem 0.4rem 1.6rem 0rem #3333330f;
        margin-left: 1.6rem;

        &::after {
          content: "";
          width: 1.2rem;
          height: 1.2rem;
          background-color: var(--palette-primary);
          border-radius: 50%;
        }

        @media (max-width: ${tablet}px) {
          display: none;
        }
      }

      &__title {
        margin-right: 0.8rem;
        margin-left: 1.2rem;
        font-size: 1.6rem;
        line-height: 1.6rem;
        color: var(--palette-black);
      }
    }

    &__city {
      grid-area: city;
    }

    &__logo {
      grid-area: logo;
      justify-self: center;
      position: relative;
      top: 2.4rem;

      @media (max-width: ${tablet}px) {
        top: 0;
      }
    }

    &__top {
      display: grid;
      grid-template-areas: "menu username process city logo search";
      grid-template-columns: min-content max-content max-content min-content auto min-content;
      align-items: center;
      max-height: 10rem;

      @media (max-width: ${tablet}px) {
        grid-template-rows: var(--sizes-navbar-height-mobile) min-content;
        grid-template-columns: min-content auto min-content;
        grid-template-areas:
          "menu logo city"
          "search search search";
      }

      &--brand {
        &.not-home {
          @media (max-width: ${tablet}px) {
            display: none;
          }
        }
      }

      &--responsive {
        justify-content: space-between;
        display: none;

        &.not-home {
          @media (max-width: ${tablet}px) {
            display: flex;
          }
        }
      }
    }

    &__title {
      font-size: 1.6rem;
      font-weight: 600;
      color: var(--palette-black-s0-l10);
    }

    &__back {
      visibility: ${props => (props.talkShop ? "hidden" : "visible")};
    }

    &__bottom {
      display: none;
      flex-wrap: nowrap;
      align-items: center;

      @media (max-width: ${tablet}px) {
        display: flex;
      }
    }

    &__search-bar {
      grid-area: search;
      height: 5.6rem;
      width: 29.6rem;

      @media (max-width: ${tablet}px) {
        position: absolute;
        left: 0;
        top: 0;
        transition: all 0.6s ease-out;
        width: 100%;
        height: 10.4rem;
        padding: 2.4rem 1.6rem;
        background-color: var(--palette-cream-s42-l93);
      }

      &--bottom {
        margin: 0;
        padding-top: 0.8rem;
        max-width: none;
      }
    }

    &__btn {
      visibility: ${props => (props.talkShop ? "hidden" : "visible")};

      &:not(:last-child) {
        padding-right: 2.4rem;
      }

      &--loyalty {
      }

      &--coupons {
        cursor: pointer;

        & path {
          fill: var(--palette-black-s0-l10);
        }
      }

      &--profile {
        margin-left: auto;
        cursor: pointer;

        & path {
          fill: var(--palette-black-s0-l10);
        }
      }

      &--settings {
        @media (max-width: ${tablet}px) {
          margin-left: auto;
        }
      }

      &--cart {
        @media (max-width: ${tablet}px) {
          margin-left: ${WITH_SELECT_ADDRESS_DROPDOWN ? "auto" : "initial"};
        }
      }

      &--hidden {
        visibility: hidden;
      }
    }

    &__search-bar--top,
    &__btn--loyalty,
    &__btn--coupons,
    &__btn--profile {
      @media (max-width: ${tablet}px) {
        display: none;
      }
    }
  }
`;

export default NavbarStyled;
