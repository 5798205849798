import React, { useEffect, useState } from "react";
import { Button, Dropdown } from "artisn-ui-react";

import Styles, { DropdownOverlayStyled } from "./SelectCityDropdown.styles";
import { SelectCityDropdownProps as Props } from "./SelectCityDropdown.types";
import CONSTANTS from "config/constants";
import useWindowSize from "hooks/useWindowSize";
import useCities from "contexts/cities/cities.hooks";
import { useFetchCities } from "services/cities/cities.service.hooks";
import { City } from "services/orders/orders.service.types";
import { getCurrentPosition } from "utils/geo.utils";
import useGeo from "contexts/geo/geo.hooks";
import { useFetchGoogleAddressFromCoords } from "services/geo/geo.service.hooks";
import { createErrorNotification } from "utils/notifications.utils";

import ArrowDownSVG from "../../../../public/assets/images/arrow-down.svg";
import LocationSVG from "../../../../public/assets/images/menu-location.svg";

const { BREAKPOINTS } = CONSTANTS;
const { tablet } = BREAKPOINTS;

const SelectCityDropdown: React.FC<Props> = props => {
  const { className } = props;
  const { width, availWidth } = useWindowSize();
  const isMobile = width <= tablet;
  const { selectedCity, setSelectedCity } = useCities();
  const { name } = selectedCity ?? {};
  const { data: cities } = useFetchCities();
  const { selectedCoordinates, setSelectedCoordinates } = useGeo();
  const { data: predictions, error } =
    useFetchGoogleAddressFromCoords(selectedCoordinates);
  const { message } = error ?? {};
  const [currentCity, setCurrentCity] = useState<City>();
  const [isCurrentLocation, setIsCurrentLocation] = useState(false);
  const [isScrollDisabled, setIsScrollDisabled] = useState(false);

  const getCurrentLocation = async () => {
    setIsCurrentLocation(true);
    if (predictions) {
      if (!currentCity) return;
      setSelectedCity(currentCity);
      return;
    }
    await getCurrentPosition(
      async position => {
        const { latitude, longitude } = position.coords;
        setSelectedCoordinates({ lat: latitude, lng: longitude });
      },
      error => {
        createErrorNotification(error.message);
      }
    );
  };

  const overlayNode = (
    <DropdownOverlayStyled
      className="DropdownOverlay DropdownOverlayCities"
      width={availWidth}
    >
      <div className="DropdownOverlay__menu">
        <div className="DropdownOverlay__current" onClick={getCurrentLocation}>
          <LocationSVG />
          Usar ubicación actual
        </div>
        <div className="DropdownOverlay__cities">
          {cities?.map((city: City) => {
            const { id, name } = city;
            return (
              <div
                key={id}
                className="DropdownOverlay__city"
                onClick={() => {
                  setSelectedCity(city);
                  setIsCurrentLocation(false);
                }}
              >
                {name.toLowerCase()}
              </div>
            );
          })}
        </div>
      </div>
    </DropdownOverlayStyled>
  );

  const buttonNode = (
    <Button className="SelectCityDropdown__button" onClick={() => {}}>
      <p className="SelectCityDropdown__title">{name.toLocaleLowerCase()}</p>
      <ArrowDownSVG />
    </Button>
  );

  useEffect(() => {
    if (message) createErrorNotification(message);
  }, [message]);

  useEffect(() => {
    if (!predictions || !predictions.length) return;
    const locality = predictions.find(
      prediction => prediction.types && prediction.types[0] === "locality"
    );
    if (!locality) return;
    const { formatted_address } = locality ?? {};
    const selectedCity = cities?.find(
      city =>
        formatted_address?.toLowerCase().indexOf(city.name.toLowerCase()) !== -1
    );
    if (!selectedCity) return;
    setCurrentCity(selectedCity);
    if (!isCurrentLocation) return;
    setSelectedCity(selectedCity);
  }, [predictions, cities, setSelectedCity, isCurrentLocation]);

  useEffect(() => {
    if (!isMobile) return;
    if (isScrollDisabled) {
      setTimeout(() => {
        document.body.style.overflow = "hidden";
      }, 100);
      return;
    }
    document.body.style.overflow = "unset";
  }, [isMobile, isScrollDisabled]);

  return (
    <Styles
      className={`SelectCityDropdown ${className}`}
      onClick={() => {
        setIsScrollDisabled(prev => !prev);
      }}
    >
      <Dropdown
        className="SelectCityDropdown__dropdown"
        overlay={overlayNode}
        target={["click"]}
        position={isMobile ? "right" : "left"}
        isFixed
      >
        {buttonNode}
      </Dropdown>
    </Styles>
  );
};

SelectCityDropdown.defaultProps = {};

export default SelectCityDropdown;
