// SEO utility functions
import router from "next/router";
import { Benefit, Category, Product } from "artisn/types";
import slugify from "slugify";

const slugifyConfig = {
  lower: true,
  locale: "es",
  strict: true
};

export const sanitizeText = (text: string) => slugify(text, slugifyConfig);

export const goToProductDetail = (
  product: Product,
  storeId: number | undefined,
  vendorId: number | undefined
) => {
  const { productId: petId, name } = product;
  const sanitizedName = sanitizeText(name);
  const queryParams: { storeId?: number; vendorId?: number } = {};
  if (storeId) queryParams.storeId = storeId;
  if (vendorId) queryParams.vendorId = vendorId;
  router.push({
    pathname: `/pets/${petId}/${sanitizedName}`,
    query: { ...queryParams }
  });
};

export const goToBenefitDetail = (benefit: Benefit) => {
  const { award } = benefit;
  if (benefit.type === "PRODUCT" && Array.isArray(award)) {
    const { productId: petId, productDescription } = award[0];
    const sanitizedName = sanitizeText(productDescription);
    router.push(`/pets/${petId}/${sanitizedName}`);
  }
};

export const goToCategory = (category: Category) => {
  const { categoryId, name } = category;
  const sanitizedName = sanitizeText(name);
  router.push(`/categories/${categoryId}/${sanitizedName}`);
};
