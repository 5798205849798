// Cities services
import { City } from "services/orders/orders.service.types";
import axiosDefault from "utils/axios.utils";
import { shouldMock } from "utils/common.utils";

/**
 * Fetches cities from Ecuador where there are pets.
 *
 * @returns {City[]} The list of cities
 */
export const fetchCities = async (): Promise<City[]> => {
  try {
    if (!shouldMock) {
      const { data } = await axiosDefault.get("api/country/1/cities", {
        params: {
          byAccount: true
        }
      });
      return data.data;
    } else {
      const { mockCities } = await import("./cities.service.mock");
      return await new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve(mockCities());
        }, 1000);
      });
    }
  } catch (e) {
    throw new Error(e.message);
  }
};
