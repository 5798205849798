import CONSTANTS from "config/constants";
import styled from "styled-components";

import { SelectCityDropdownStyledProps as Props } from "./SelectCityDropdown.types";
import { DropdownOverlayStyledProps as OverlayProps } from "./SelectCityDropdown.types";

const { tablet } = CONSTANTS.BREAKPOINTS;

const SelectCityDropdownStyled = styled.div<Props>`
  &.SelectCityDropdown {
  }

  .SelectCityDropdown {
    &__title {
      width: 10rem;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      text-transform: capitalize;

      @media (max-width: ${tablet}px) {
        width: 8rem;
      }
    }

    &__button {
      background-color: transparent;
      border-color: transparent;
      box-shadow: none;
      color: var(--palette-black);
      font-size: 1.6rem;

      @media (max-width: ${tablet}px) {
        padding: 0;
        width: 100%;
      }

      svg {
        margin-left: 1.2rem;
      }
    }
  }
`;

export const DropdownOverlayStyled = styled.div<OverlayProps>`
  padding: 2.8rem 2rem;

  @media (max-width: ${tablet}px) {
    width: ${props => props.width + "px"};
    padding: 0;
  }

  .DropdownOverlay {
    &__menu {
      @media (max-width: ${tablet}px) {
        padding: 2.8rem 2rem;
        border-top-left-radius: 2.4rem;
        border-top-right-radius: 2.4rem;
        background-color: var(--palette-white);
      }
    }

    &__current {
      color: var(--palette-primary);
      display: flex;
      align-items: center;
      font-size: 1.6rem;
      font-weight: 600;
      cursor: pointer;
      border-bottom: 0.1rem solid var(--palette-gray-s0-l95);
      padding-bottom: 1.6rem;

      svg {
        margin-right: 1.2rem;
      }
    }

    &__cities {
      max-height: 40rem;
      overflow: auto;
      scrollbar-width: none;

      &::-webkit-scrollbar {
        visibility: hidden;
      }
    }

    &__city {
      color: var(--palette-blue-s51-l16);
      font-size: 1.6rem;
      font-weight: 600;
      cursor: pointer;
      text-transform: capitalize;

      &:not(:last-child) {
        border-bottom: 0.1rem solid var(--palette-gray-s0-l95);
        padding: 1.6rem 0;
      }

      &:last-child {
        padding-top: 1.6rem;
      }
    }
  }
`;

export default SelectCityDropdownStyled;
