import { useContext } from "react";

import { CitiesContext } from "./cities.context";
import { CitiesProviderValue } from "./cities.context.types";

const useCities = () => {
  const context = useContext<CitiesProviderValue>(CitiesContext);
  if (typeof context === "undefined") {
    throw new Error("useCities must be used within a CitiesProvider");
  }
  return context;
};

export default useCities;
