// Product services
import { BaseProduct } from "artisn/types";

import { shouldMock } from "utils/common.utils";
import axiosDefault from "utils/axios.utils";
import { FetchCategoriesWithProductsPayload } from "./products.service.types";
import { FetchProductsPayload } from "./products.service.types";
import { FetchProductDetailsPayload } from "./products.service.types";
import { NewPaginatedResponse } from "types/pagination.types";
import { normalizeProductDetails } from "utils/product.utils";
import { CategoryWithPets } from "components/categoryGrid/CategoryGrid/CategoryGrid.types";
import { PetDetails } from "components/product/Product/Product.types";

/**
 * Fetches a list of paginated products.
 *
 * @param {FetchProductsPayload} config The config needed to fetch a page
 * @returns {NewPaginatedResponse<BaseProduct>} The paginated list of products
 */
export const fetchProducts = async (
  config: FetchProductsPayload
): Promise<NewPaginatedResponse<BaseProduct>> => {
  const { catalogueId, categoryId, size = 20 } = config;
  const { page, query, cityId, tags } = config;

  try {
    if (!shouldMock) {
      const { data: response } = await axiosDefault.get(`/api/v3/productsBy`, {
        params: {
          channelId: catalogueId,
          catalogueId,
          categoryId,
          size,
          orderBy: "creation",
          orderMethod: "asc",
          page,
          query,
          cityId,
          tags
        }
      });

      return response;
    } else {
      const { mockProductsPaginated } = await import("./products.service.mock");
      return await new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve(
            mockProductsPaginated(
              {
                page,
                pageSize: size
              },
              { query: query ?? "query", field: "" },
              { imageHeight: 300, imageWidth: 300 }
            )
          );
        }, 1000);
      });
    }
  } catch (e) {
    throw new Error(e.message);
  }
};

/**
 * Fetches a list of products group by their parent categories
 *
 * @param {FetchCategoriesWithProductsPayload} config The config needed to fetch a page
 * @returns {RawPaginatedResponse<CategoryWithProducts>} The paginated list of categories
 */
export const fetchCategoriesWithProducts = async (
  config: FetchCategoriesWithProductsPayload
): Promise<NewPaginatedResponse<CategoryWithPets>> => {
  try {
    const { catalogueId, categoryId, size = 5, cityId } = config;
    const { page, query, tags } = config;
    if (!shouldMock) {
      const { productsByGroup = 20 } = config;
      const { data } = await axiosDefault.get(`/api/v3/productsBy`, {
        params: {
          channelId: catalogueId,
          catalogueId,
          categoryId,
          groupBy: "category",
          productsByGroup,
          size,
          orderBy: "creation",
          orderMethod: "asc",
          page,
          query,
          cityId,
          tags
        }
      });

      return data;
    } else {
      const { mockPaginatedCategoriesWithProduct } = await import(
        "./products.service.mock"
      );
      return await new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve(mockPaginatedCategoriesWithProduct(categoryId));
        }, 1000);
      });
    }
  } catch (e) {
    throw new Error(e.message);
  }
};

/**
 * Fetches a product's details.
 *
 * @param {FetchProductDetailsPayload} config The config needed to fetch the product
 * @returns {ProductDetails} The product details object
 */
export const fetchProductDetails = async (
  config: FetchProductDetailsPayload
): Promise<PetDetails> => {
  try {
    const { withCategories } = config;
    const { vendorId, catalogueId, storeId, categoryId, productId } = config;

    if (!shouldMock) {
      const { data } = await axiosDefault.get(`/v1/products/${productId}`, {
        params: {
          vendorId,
          storeId,
          channelId: catalogueId,
          catalogueId,
          categoryId,
          withCategories
        }
      });

      if (!data.data) {
        throw new Error(`Couldn't find a product with id ${productId}`);
      }

      return normalizeProductDetails(data.data);
    } else {
      const { mockProductDetails } = await import("./products.service.mock");
      return await new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve(
            mockProductDetails(productId, { imageHeight: 600, imageWidth: 600 })
          );
        }, 1000);
      });
    }
  } catch (e) {
    throw new Error(e.message);
  }
};

/**
 * Fetches a base product array.
 *
 * @returns {BaseProduct[]} The base products array
 */
export const fetchRecommendedProducts = async (): Promise<BaseProduct[]> => {
  try {
    if (!shouldMock) {
      return await new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve([]);
        }, 1000);
      });
    } else {
      const { mockRecommendedProducts } = await import(
        "./products.service.mock"
      );
      return await new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve(mockRecommendedProducts);
        }, 1000);
      });
    }
  } catch (e) {
    throw new Error(e.message);
  }
};
